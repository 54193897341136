<script lang="ts" setup>
import {
  ref,
  computed,
  onMounted,
  useI18n,
  useRouteBaseName,
  useDevice,
  useAirpazCookie,
  watch,
  useTracker,
  useHead,
  useRoute,
} from '#imports';

useHead({
  bodyAttrs: {
    class: 'max-w-screen-sm mx-auto my-0',
  },
});

const route = useRoute();
const { t } = useI18n();
const device = useDevice();
const { jitsuEvent } = useTracker();
const getRouteBaseName = useRouteBaseName();

const hideAppBanner = useAirpazCookie('apz_hideAppBanner', { maxAge: 86400 });
const isAppInstalled = ref(false);
const appBannerRef = ref<HTMLElement | null>(null);

const title = computed(() => (route.meta.title ? t(route.meta.title) : undefined));
const backTo = computed(() => route.meta.backTo);
const showNavbar = computed(
  () =>
    !device.isAirpazWebView &&
    ![
      'index',
      'flight',
      'flight-search',
      'flight-confirm',
      'promo-view-special-deals',
      'pay-trxId-choose',
      'pay-trxId-confirmation',
      'order',
      'retrieve',
      'retrieve-list',
      'account-order',
      'account-order-id',
      'order-id',
      'hotel',
      'account-order-id',
      'order-id',
      'account',
      'account-profile',
      'account-password',
      'account-contact',
      'account-passenger',
      'account-bind',
      'hotel-search',
      'choose-country',
      'promo',
      'login',
      'aftersales-change-flight-submission-id-search',
      'flight-add-ons-bookId-product',
    ].includes(getRouteBaseName(route) ?? ''),
);

const showFooter = computed(
  () =>
    !device.isAirpazWebView &&
    ![
      'flight-search',
      'flight-book-bookId',
      'flight-confirm',
      'reset',
      'reset-pass-id',
      'feedback',
      'feedback-concern',
      'feedback-email',
      'pay-trxId-choose',
      'pay-trxId-confirmation',
      'order',
      'retrieve',
      'account-order',
      'account-order-id',
      'order-id',
      'signup-setup-account',
      'login',
      'signup',
      'signup-verify',
      'account-order-id',
      'order-id',
      'account',
      'account-profile',
      'account-password',
      'account-contact',
      'account-passenger',
      'account-bind',
      'hotel-search',
      'terms-uses',
      'choose-country',
      'promo-view-slug',
      'aftersales-change-flight-submission-id-search',
      'newsletter-confirm',
      'newsletter-unsubscribe',
      'newsletter-choose-language',
      'newsletter-change-language',
      'flight-book-bookId-addons',
      'flight-add-ons-bookId-product',
    ].includes(getRouteBaseName(route) ?? ''),
);
const showAppBanner = computed(() => {
  return (
    !device.isAirpazWebView &&
    !device.isCrawler &&
    !device.isSafari &&
    !hideAppBanner.value &&
    !isAppInstalled.value &&
    ![
      'flight-search',
      'flight-confirm',
      'flight-book-bookId',
      'pay-trxId-choose',
      'pay-trxId-confirmation',
      'aftersales-change-flight-submission-id-search',
    ].includes(getRouteBaseName(route) ?? '')
  );
});

watch(showAppBanner, (val) => {
  if (val) jitsuEvent('app-banner-displayed');
});

function closeBanner() {
  jitsuEvent('app-banner-close-click');

  hideAppBanner.value = 'true';
}

onMounted(async () => {
  if ((device.isAndroid || device.isIos) && !device.isAirpazWebView) {
    try {
      // @ts-expect-error
      if (typeof navigator.getInstalledRelatedApps === 'undefined') return;

      // @ts-expect-error
      const relatedApps = await navigator.getInstalledRelatedApps();
      if (relatedApps.length) isAppInstalled.value = true;
    } catch (_e) {}
  }
});
</script>

<template>
  <div>
    <LazyAppInstallBanner
      v-if="showAppBanner"
      ref="appBannerRef"
      @close="closeBanner"
    />
    <AppNavbarMobile
      v-if="showNavbar"
      :title="title"
      :back-to="backTo"
      :with-drawer="(route.meta.withDrawer as boolean | undefined)"
      class="sticky top-0 z-30"
    />
    <slot />
    <AppFooterMobile v-if="showFooter" />
  </div>
</template>
